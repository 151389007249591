@import '/styles/mixins.sass'

.Page
  display: flex
  flex-direction: column
  min-height: 100dvh
  a
    +fancy-link
  .content
    flex: 1
    +container
    h1, h2, h3
      color: var(--color-grey-dark)
      line-height: 150%
      font-weight: 300
    h1, h2
      font-size: 2rem
    h3
      font-size: 1.5rem
    ul
      list-style-type: disc

    form
      label
        max-width: 500px
        display: flex
        flex-direction: column
        input, textarea
          flex: 1
