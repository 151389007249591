.Modal
  &:focus-visible
    outline: none
  .react-responsive-modal-closeButton
    right: 1.5em
    svg
      width: 1.5em
      fill: #aaa
      &:hover
        fill: #555
  .join
    margin: 1em 1.5em 2em

    .user
      display: flex
      border: 1
      .avatar
        width: 4em
        height: 4em
        border-radius: 2em
      .info
        display: flex
        padding-left: 1em
        flex-direction: column
        justify-content: center
        height: 4em
        line-height: 1.5em
        .name
          font-weight: bold
          color: #555
        .github
          font-family: monospace
          white-space: pre

    .buttons
      margin-top: 1em
      margin-bottom: -1em
      display: flex
      gap: 0.66em
      justify-content: flex-start
      flex-direction: row-reverse

    .button
      position: relative
      overflow: visible
      display: inline-block
      padding: 0.5em 1em
      border: 1px solid #d4d4d4
      margin: 0
      text-decoration: none
      text-align: center
      text-shadow: 1px 1px 0 #fff
      font: 11px / normal sans-serif
      color: #333
      white-space: nowrap
      cursor: pointer
      outline: none
      background-color: #ececec
      background-image: linear-gradient(#f4f4f4, #ececec)
      background-clip: padding-box
      border-radius: 0.2em
      zoom: 1
      &.primary
        font-weight: bold
      &:hover,
      &:focus,
      &:active,
      &.active
        border-color: #3072b3
        border-bottom-color: #2a65a0
        text-decoration: none
        text-shadow: -1px -1px 0 rgba(0,0,0,0.3)
        color: #fff
        background-color: #3c8dde
        background-image: linear-gradient(#599bdc, #3072b3)
