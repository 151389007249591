@import '/styles/mixins.sass'

nav.Navbar
  color: white
  font-weight: bold
  background: var(--color-red)
  min-height: 40px
  display: flex
  justify-content: center
  transition: all 350ms ease-in-out
  position: relative
  &:hover
    background: var(--color-red-dark)
  &.after-dark
    background: var(--color-night)
    a
      +fancy-link
      &:hover
        background: var(--color-night)
    .nav-links.open
      background: var(--color-night)

  a
    +fancy-link
    display: flex
    flex-wrap: wrap
    align-items: center
    cursor: pointer
    line-height: 40px
    margin: 0
    padding: 0 1em
    color: inherit
    text-decoration: none
    transition: all 350ms ease-in-out
    &:hover
      background: var(--color-red)
      
  .menu-toggle
    display: none
    background: none
    border: none
    color: white
    font-size: 1.5rem
    cursor: pointer
    padding: 0.5rem
    position: absolute
    right: 0
    z-index: 2

  .nav-links
    display: flex
    align-items: center

  svg, img
    height: 1em
    font-size: 1.2rem
    margin-right: 0.5rem

  +tablet
    .menu-toggle
      display: block

    .nav-links
      display: none
      flex-direction: column
      position: absolute
      top: 0
      right: 0
      padding: 1em
      text-align: left
      z-index: 1
      box-shadow: -5px 0 10px rgba(0, 0, 0, 0.3)

    a
      padding: 1em
      display: flex
      justify-content: flex-start
      line-height: 0

    .nav-links.open
      display: flex
      background: var(--color-red)
      align-items: flex-start
 
