*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

:root {
  --color-red: #ee3124;
  --color-red-dark: #d72b1f;
  --color-grey: #9b9ba9;
  --color-grey-dark: #1b1340;
  --color-yellow: #fff854;
  --color-night: #0a0a1d;
}

html, body, #app {
  min-height: 100dvh;
  font-family: Roboto, sans-serif;
}

.react-responsive-modal-root {
  z-index: 1000;
  position: fixed;
  inset: 0;
}

.react-responsive-modal-overlay {
  z-index: -1;
  background: #00000080;
  position: fixed;
  inset: 0;
}

.react-responsive-modal-container {
  text-align: center;
  outline: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.react-responsive-modal-containerCenter:after {
  content: "";
  vertical-align: middle;
  width: 0;
  height: 100%;
  display: inline-block;
}

.react-responsive-modal-modal {
  text-align: left;
  vertical-align: middle;
  background: #fff;
  max-width: 800px;
  margin: 1.2rem;
  padding: 1.2rem;
  display: inline-block;
  position: relative;
  overflow-y: auto;
  box-shadow: 0 12px 15px #00000040;
}

.react-responsive-modal-closeButton {
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0;
  display: flex;
  position: absolute;
  top: 14px;
  right: 14px;
}

.react-responsive-modal-overlay, .react-responsive-modal-container, .react-responsive-modal-modal {
  animation-fill-mode: forwards !important;
}

@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    opacity: 0;
    transform: scale(.96);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.96);
  }
}

@keyframes backdrop-in {
  from {
    background: none;
  }

  to {
    background: #00000054;
  }
}

@keyframes backdrop-out {
  from {
    background: #00000054;
  }

  to {
    background: none;
  }
}

@keyframes modal-in {
  from {
    opacity: 0;
    translate: 0 3rem;
  }

  to {
    opacity: 1;
    translate: 0;
  }
}

@keyframes modal-out {
  from {
    opacity: 1;
    translate: 0;
  }

  to {
    opacity: 0;
    translate: 0 3rem;
  }
}

.Modal {
  opacity: 0;
  pointer-events: inherit;
  background: #fff;
  border: 0;
  border-radius: 5px;
  width: 400px;
  padding: 0;
  box-shadow: 0 22px 70px 4px #00000054;
}

.Modal .join {
  margin: 2rem;
}

.Modal .join h2 {
  letter-spacing: .1em;
  text-transform: uppercase;
  padding-bottom: 1em;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: normal;
}

.Modal:focus-visible {
  outline: none;
}

.Modal .react-responsive-modal-closeButton {
  right: 1.5em;
}

.Modal .react-responsive-modal-closeButton svg {
  fill: #aaa;
  width: 1.5em;
}

.Modal .react-responsive-modal-closeButton svg:hover {
  fill: #555;
}

.Modal .join {
  margin: 1em 1.5em 2em;
}

.Modal .join .user {
  border: 1px;
  display: flex;
}

.Modal .join .user .avatar {
  border-radius: 2em;
  width: 4em;
  height: 4em;
}

.Modal .join .user .info {
  flex-direction: column;
  justify-content: center;
  height: 4em;
  padding-left: 1em;
  line-height: 1.5em;
  display: flex;
}

.Modal .join .user .info .name {
  color: #555;
  font-weight: bold;
}

.Modal .join .user .info .github {
  white-space: pre;
  font-family: monospace;
}

.Modal .join .buttons {
  flex-direction: row-reverse;
  justify-content: flex-start;
  gap: .66em;
  margin-top: 1em;
  margin-bottom: -1em;
  display: flex;
}

.Modal .join .button {
  text-align: center;
  text-shadow: 1px 1px #fff;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
  zoom: 1;
  background-color: #ececec;
  background-image: linear-gradient(#f4f4f4, #ececec);
  background-clip: padding-box;
  border: 1px solid #d4d4d4;
  border-radius: .2em;
  outline: none;
  margin: 0;
  padding: .5em 1em;
  font: 11px sans-serif;
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.Modal .join .button.primary {
  font-weight: bold;
}

.Modal .join .button:hover, .Modal .join .button:focus, .Modal .join .button:active, .Modal .join .button.active {
  text-shadow: -1px -1px #0000004d;
  color: #fff;
  background-color: #3c8dde;
  background-image: linear-gradient(#599bdc, #3072b3);
  border-color: #3072b3 #3072b3 #2a65a0;
  text-decoration: none;
}

nav.Navbar {
  color: #fff;
  background: var(--color-red);
  justify-content: center;
  min-height: 40px;
  font-weight: bold;
  transition: all .35s ease-in-out;
  display: flex;
  position: relative;
}

nav.Navbar:hover {
  background: var(--color-red-dark);
}

nav.Navbar.after-dark {
  background: var(--color-night);
}

nav.Navbar.after-dark a {
  text-decoration: none;
  position: relative;
}

nav.Navbar.after-dark a:before {
  content: "";
  transform-origin: 100%;
  background-color: currentColor;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  transition: transform .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

nav.Navbar.after-dark a:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

nav.Navbar.after-dark a:hover, nav.Navbar.after-dark .nav-links.open {
  background: var(--color-night);
}

nav.Navbar a {
  cursor: pointer;
  color: inherit;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0 1em;
  line-height: 40px;
  text-decoration: none;
  transition: all .35s ease-in-out;
  display: flex;
  position: relative;
}

nav.Navbar a:before {
  content: "";
  transform-origin: 100%;
  background-color: currentColor;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  transition: transform .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

nav.Navbar a:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

nav.Navbar a:hover {
  background: var(--color-red);
}

nav.Navbar .menu-toggle {
  color: #fff;
  cursor: pointer;
  z-index: 2;
  background: none;
  border: none;
  padding: .5rem;
  font-size: 1.5rem;
  display: none;
  position: absolute;
  right: 0;
}

nav.Navbar .nav-links {
  align-items: center;
  display: flex;
}

nav.Navbar svg, nav.Navbar img {
  height: 1em;
  margin-right: .5rem;
  font-size: 1.2rem;
}

@media only screen and (width <= 992px) {
  nav.Navbar .menu-toggle {
    display: block;
  }

  nav.Navbar .nav-links {
    text-align: left;
    z-index: 1;
    flex-direction: column;
    padding: 1em;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: -5px 0 10px #0000004d;
  }

  nav.Navbar a {
    justify-content: flex-start;
    padding: 1em;
    line-height: 0;
    display: flex;
  }

  nav.Navbar .nav-links.open {
    background: var(--color-red);
    align-items: flex-start;
    display: flex;
  }
}

footer.Footer {
  color: #fff;
  background: var(--color-grey);
  justify-content: center;
  min-height: 50px;
  font-weight: bold;
  display: flex;
}

footer.Footer a {
  cursor: pointer;
  color: inherit;
  margin: 0;
  padding: 0 1em;
  line-height: 50px;
  text-decoration: none;
  transition: all .35s ease-in-out;
  display: block;
}

footer.Footer a:hover {
  color: var(--color-yellow);
  background: var(--color-red);
  translate: 0 -4px;
}

footer.Footer a svg {
  vertical-align: sub;
  margin-right: .33rem;
  font-size: 1.2rem;
}

@media only screen and (width <= 992px) {
  footer.Footer a span.label {
    display: none;
  }
}

.FourOhFour {
  background: var(--color-red);
  color: #fff;
  flex-direction: column;
  min-height: 100dvh;
  display: flex;
}

.FourOhFour main {
  flex: 1;
  display: flex;
}

.FourOhFour main .container {
  flex: 1;
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media only screen and (width <= 768px) {
  .FourOhFour main .container {
    max-width: 750px;
  }
}

@media only screen and (width <= 992px) {
  .FourOhFour main .container {
    max-width: 970px;
  }
}

.FourOhFour main .container .title {
  text-align: center;
  letter-spacing: .25rem;
  text-transform: uppercase;
  align-self: center;
  padding-top: 7rem;
  font-family: Inter, sans-serif;
  font-size: 36px;
  line-height: 1.66;
}

.FourOhFour main .container .title h1 {
  font-size: 2.25rem;
  font-weight: normal;
}

.FourOhFour main .container .title h1 .JS {
  margin-left: .33em;
  font-weight: bold;
}

.FourOhFour main .container .title .typing {
  font-size: 1.25rem;
  display: block;
}

.FourOhFour main .container .logo {
  align-items: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.FourOhFour main .container .logo svg {
  height: 60dvh;
}

@media only screen and (width <= 992px) {
  .FourOhFour main .container .logo svg {
    max-height: 50dvh;
  }
}

@media only screen and (width <= 768px) {
  .FourOhFour main .container .logo svg {
    max-height: 40dvh;
  }
}

.FourOhFour main .container .logo svg path, .FourOhFour main .container .logo svg g, .FourOhFour main .container .logo svg polygon, .FourOhFour main .container .logo svg circle {
  fill: none;
  stroke: currentColor;
  stroke-width: .5px;
  stroke-dasharray: 2 2;
  stroke-linejoin: round;
}

.Home {
  flex-direction: column;
  min-height: 100dvh;
  display: flex;
}

.Home main {
  flex: 1;
}

.Home main .hero {
  color: #fff;
  background: var(--color-red);
  align-items: stretch;
  display: flex;
  position: relative;
}

.Home main .hero.after-dark {
  background: linear-gradient(to top, #2c203d 0%, #181a2c 50%, #0a0a1e 100%);
}

.Home main .hero .cn-tower {
  width: 80%;
  height: 80%;
  margin-left: -2%;
  position: absolute;
  bottom: 0;
}

.Home main .hero .container {
  flex-direction: column;
  flex: 1;
  max-width: 970px;
  height: 74dvh;
  margin-left: auto;
  margin-right: auto;
  padding-top: 7rem;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

@media only screen and (width <= 768px) {
  .Home main .hero .container {
    max-width: 750px;
  }
}

@media only screen and (width <= 992px) {
  .Home main .hero .container {
    background-size: 50dvh;
    max-width: 970px;
  }
}

@media only screen and (width <= 768px) {
  .Home main .hero .container {
    background-size: 40dvh;
  }
}

.Home main .hero .container .title {
  text-align: center;
  letter-spacing: .25rem;
  text-transform: uppercase;
  align-self: center;
  font-family: Inter, sans-serif;
  font-size: 36px;
  line-height: 1.66;
}

.Home main .hero .container .title h1 {
  font-size: 2.25rem;
  font-weight: normal;
}

.Home main .hero .container .title h1 .JS {
  margin-left: .33em;
  font-weight: bold;
}

.Home main .hero .container .title .typing {
  font-size: 1.25rem;
  display: block;
}

.Home main .content {
  flex-direction: column;
  max-width: 62rem;
  margin: 2rem auto;
  display: flex;
}

.Home main .content .container {
  flex-direction: row;
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

@media only screen and (width <= 768px) {
  .Home main .content .container {
    max-width: 750px;
  }
}

@media only screen and (width <= 992px) {
  .Home main .content .container {
    flex-direction: column;
    max-width: 970px;
  }
}

.Home main .content .container.top {
  gap: 2rem;
}

.Home main .content .container.top .description {
  flex: auto;
}

.Home main .content .container.top .description a {
  text-decoration: none;
  position: relative;
}

.Home main .content .container.top .description a:before {
  content: "";
  transform-origin: 100%;
  background-color: currentColor;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  transition: transform .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.Home main .content .container.top .description a:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

@media only screen and (width <= 992px) {
  .Home main .content .container.top .description {
    margin-bottom: 2rem;
  }
}

.Home main .content .container.top .description h2 {
  color: var(--color-grey-dark);
  font-size: 2rem;
  font-weight: 300;
  line-height: 48px;
}

.Home main .content .container.top .description ul {
  margin-top: 1rem;
  font-size: 1.25rem;
  line-height: 1.4;
  list-style: none;
}

.Home main .content .container.top .GuildCard {
  min-width: 265px;
}

.Home main .content .events {
  gap: 2rem;
  max-width: 100%;
}

.Home main .content .events .future, .Home main .content .events .past {
  flex: 1;
}

.Home main .content .events h2 {
  color: var(--color-grey-dark);
  padding: 1rem 0 .5rem .5rem;
  font-size: 2rem;
  font-weight: 300;
  line-height: 48px;
}

.ProfileCard {
  text-align: center;
  color: #000;
  background-image: linear-gradient(0deg, var(--color-yellow), white);
  background-position: 0 -100%;
  background-repeat: no-repeat;
  background-size: 100% 200%;
  border: 1px solid #0000;
  border-radius: 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
  translate: 0;
  box-shadow: 0 0 .75rem #0a254014;
}

.ProfileCard:hover, .ProfileCard:focus-visible {
  outline-color: var(--color-red);
  background-position: 0 -50%;
  border: 1px solid #00000017;
  translate: 0 -.2rem;
  box-shadow: 0 0 .75rem #0a254026;
}

.ProfileCard:hover:after, .ProfileCard:focus-visible:after {
  opacity: 1;
  inset: auto auto .5rem;
}

.ProfileCard:hover .ProfileCardName, .ProfileCard:focus-visible .ProfileCardName {
  color: var(--color-red);
}

.ProfileCard:hover .ProfileCardAvatar, .ProfileCard:focus-visible .ProfileCardAvatar {
  scale: 1.1;
}

.ProfileCard:hover .ProfileCardUrl:after, .ProfileCard:focus-visible .ProfileCardUrl:after {
  width: 100%;
}

.ProfileCard:before {
  display: none;
}

.ProfileCard .ProfileCardAvatar {
  border-radius: 50%;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  transition: scale .3s ease-in-out;
  scale: 1;
}

.ProfileCard .ProfileCardDesc {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.ProfileCard .ProfileCardName {
  font-size: 1.2rem;
  font-weight: bold;
  transition: color .4s ease-in-out;
}

.ProfileCard .ProfileCardUrl {
  color: var(--color-grey);
  margin-top: .35rem;
  font-size: .85rem;
  position: relative;
}

.ProfileCard .ProfileCardUrl:after {
  content: "";
  background-color: #d5d5d5;
  width: 0;
  height: 1px;
  transition: width .3s ease-in-out;
  position: absolute;
  inset: auto auto 0 50%;
  translate: -50%;
}

.Page {
  flex-direction: column;
  min-height: 100dvh;
  display: flex;
}

.Page a {
  text-decoration: none;
  position: relative;
}

.Page a:before {
  content: "";
  transform-origin: 100%;
  background-color: currentColor;
  border-radius: 1px;
  width: 100%;
  height: 1px;
  transition: transform .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.Page a:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

.Page .content {
  flex: 1;
  max-width: 970px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (width <= 768px) {
  .Page .content {
    max-width: 750px;
  }
}

@media only screen and (width <= 992px) {
  .Page .content {
    max-width: 970px;
  }
}

.Page .content h1, .Page .content h2, .Page .content h3 {
  color: var(--color-grey-dark);
  font-weight: 300;
  line-height: 150%;
}

.Page .content h1, .Page .content h2 {
  font-size: 2rem;
}

.Page .content h3 {
  font-size: 1.5rem;
}

.Page .content ul {
  list-style-type: disc;
}

.Page .content form label {
  flex-direction: column;
  max-width: 500px;
  display: flex;
}

.Page .content form label input, .Page .content form label textarea {
  flex: 1;
}

.Volunteers > p {
  line-height: 1.5;
}

.Volunteers > h2 {
  margin-top: 3rem;
}

.Volunteers .volunteerList {
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  display: grid;
}

.Volunteers .volunteerList:last-child {
  margin-bottom: 5rem;
}

/*# sourceMappingURL=index.a6bd7db4.css.map */
