.ProfileCard
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  gap: 1rem
  border: 1px solid transparent
  padding: 1.5rem 1.5rem
  border-radius: 1.5rem
  box-shadow: rgba(10, 37, 64, 0.08) 0 0 .75rem
  transition: all 300ms ease-in-out
  color: #000
  position: relative
  background-image: linear-gradient(0deg, var(--color-yellow), white)
  background-size: 100% 200%
  background-position: 0 -100%
  background-repeat: no-repeat
  translate: 0 0
  &:hover,
  &:focus-visible
    box-shadow: rgba(10, 37, 64, 0.15) 0 0 .75rem // from Guild widgets
    border: 1px solid rgba(#000, 9%)
    background-position: 0 -50%
    translate: 0 -.2rem
    outline-color: var(--color-red)
    &::after
      inset: auto auto .5rem auto
      opacity: 1
    .ProfileCardName
      color: var(--color-red)
    .ProfileCardAvatar
      scale: 1.1
    .ProfileCardUrl
      &::after
        width: 100%
  &::before
    display: none

  .ProfileCardAvatar
    width: 6rem
    height: 6rem
    border-radius: 50%
    flex-shrink: 0
    scale: 1
    transition: scale 300ms ease-in-out
  .ProfileCardDesc
    display: flex
    flex-direction: column
    align-items: center
  .ProfileCardName
    transition: color 400ms ease-in-out
    font-size: 1.2rem
    font-weight: bold
  .ProfileCardUrl
    color: var(--color-grey)
    font-size: .85rem
    margin-top: .35rem
    position: relative
    &:after
      content: ""
      width: 0
      position: absolute
      inset: auto auto 0 50%
      translate: -50% 0
      height: 1px
      background-color: #d5d5d5
      transition: width 300ms ease-in-out
