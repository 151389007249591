$screen-phone-max:    768px
$screen-tablet-max:   992px
$screen-laptop-max:   1200px
$screen-desktop-max:  1440px

=phone
  @media only screen and (max-width: $screen-phone-max)
    @content

=tablet
  @media only screen and (max-width: $screen-tablet-max)
    @content

=laptop
  @media only screen and (max-width: $screen-laptop-max)
    @content

=desktop
  @media only screen and (max-width: $screen-desktop-max)
    @content

=container
  padding-right: 15px
  padding-left: 15px
  margin-right: auto
  margin-left: auto
  max-width: 970px

  +phone
    max-width: 750px
  +tablet
    max-width: 970px
  // +laptop
  //   width: 1170px

=fancy-link
  text-decoration: none
  position: relative
  &::before
    content: ''
    position: absolute
    width: 100%
    height: 1px
    border-radius: 1px
    background-color: currentcolor
    bottom: 0
    left: 0
    transform-origin: right
    transform: scaleX(0)
    transition: transform .3s ease-in-out

  &:hover::before
    transform-origin: left
    transform: scaleX(1)
